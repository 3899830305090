@import '../../../../../Content/styles/itp/variables';
@import '_theme-color.scss';
@import '../../../../../Content/styles/itp/_color.scss';

* {
    font-size: 1.1rem;
}
.survey-details > tbody > tr > td > div {
    font-size: 1.3em;
}
.survey-details > tbody > tr > td > div > span {
    font-size: 1em;
}