﻿.brand-color {
    color: $brand-color;
}

.brand-fill {
    fill: $brand-color;
}

.brand-stroke {
    stroke: $brand-color;
}

.brand-background-color {
    background-color: $brand-color;
}

.brand-border-color {
    border-color: $brand-color;
}

.light-brand-background-color {
    background-color: $secondary-background-color;
}

.light-brand-background-color-disable {
    background-color: $grey-light;
    color: $grey-dark;
}

.text-color-lighter {
    color: $grey-lighter;
}

.border-color-lighter {
    border-color: $grey-lighter;
}

.background-color-white {
    background-color: $white;
}

.red {
    background-color: $red;
    color: $white;
}

.red-color {
    color: $red;
}

.border-color-red {
    border: $red;
}

.basket-status-approved {
    background-color: $green;
}

.basket-status-submitted {
    background-color: $blue;
}

.basket-status-comingsoon {
    background-color: $grey;
}

.basket-status-open {
    background-color: $yellow;
}

.basket-status-saved {
    background-color: $purple;
}

.basket-status-todo {
    background-color: $red;
}

.basket-status-expired {
    background-color: $orange;
}

.grey-dark-color {
    color: $grey-dark;
}

.grey-darker-color {
    color: $grey-darker;
}

a.grey-dark-color,
a.grey-darker-color:focus,
a.grey-darker-color:hover {
    cursor: pointer;
    color: $grey-dark;
    text-decoration: none;
}

.transparant-grey-light-background-color {
    background: rgba(245, 245, 245, 0.47843137254901963);
}

.grey-light-background-color {
    background: #f7f7f7;
}

.grey-dark-background-color {
    background: $grey-blue-dark;
}

